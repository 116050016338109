import { ImagePreview, Toast } from 'vant'
import { commonFileUploadApi } from '@/api/home'
export default {
	props: {
		tabsActive: {
			type: [String, Number]
		},
		paramsUrl: {
			type: Object,
			default: () => {}
		}
	},
	created() {
		// console.log('created-xxx', this.tabsActive)
		// let channelVisitCreateInfo = {}
    // if (localStorage.getItem('channelVisitCreateInfo')) {
    //   channelVisitCreateInfo = JSON.parse(localStorage.getItem('channelVisitCreateInfo'))
    // }
		// let currentData = channelVisitCreateInfo[this.paramsUrl.art_id]
		// console.log('currentData', currentData)
		// if (this.tabsActive === 2 && currentData) {
		// 	this.signInFileList = currentData.signInFileList || []
    //   this.signOutFileList = currentData.signOutFileList || []
    //   this.materialFileList = currentData.materialFileList || []
    //   this.inventoryFileList = currentData.inventoryFileList || []
		// 	console.log('signInFileList', this.signInFileList)
		// } else if (this.tabsActive === 3 && currentData && currentData.toSaleList) {
		// 	this.toSaleList = currentData.toSaleList
		// 	console.log('toSaleList', this.toSaleList)
		// } else if (this.tabsActive === 4 && currentData) {
		// 	this.remark = currentData.remark || ''
		// }
	},
	beforeDestroy () {
    // let channelVisitCreateInfo = {}
		// let currentVisitCreateInfo = {}
    // if (localStorage.getItem('channelVisitCreateInfo')) {
    //   channelVisitCreateInfo = JSON.parse(localStorage.getItem('channelVisitCreateInfo'))
		// 	currentVisitCreateInfo = channelVisitCreateInfo[this.paramsUrl.art_id] || {}
    // }
		// if (this.tabsActive === 0) {
		// 	channelVisitCreateInfo[this.paramsUrl.art_id] = {
		// 		...currentVisitCreateInfo,
		// 		protoList: this.protoList,
		// 		materialList: this.materialList
		// 	}
		// } else if (this.tabsActive === 1) {
		// 	channelVisitCreateInfo[this.paramsUrl.art_id] = {
		// 		...currentVisitCreateInfo,
		// 		productList: this.productList,
		// 	}
		// } else if (this.tabsActive === 2) {
		// 	channelVisitCreateInfo[this.paramsUrl.art_id] = {
		// 		...currentVisitCreateInfo,
		// 		signInFileList: this.signInFileList,
		// 		signOutFileList: this.signOutFileList,
		// 		materialFileList: this.materialFileList,
		// 		inventoryFileList: this.inventoryFileList
		// 	}
		// } else if (this.tabsActive === 3) {
		// 	channelVisitCreateInfo[this.paramsUrl.art_id] = {
		// 		...currentVisitCreateInfo,
		// 		toSaleList: this.toSaleList
		// 	}
		// } else if (this.tabsActive === 4) {
		// 	channelVisitCreateInfo[this.paramsUrl.art_id] = {
		// 		...currentVisitCreateInfo,
		// 		remark: this.remark
		// 	}
		// }
    // localStorage.setItem('channelVisitCreateInfo', JSON.stringify(channelVisitCreateInfo))
	},
	methods: {
		imgPreview(urlList) {
      ImagePreview(urlList)
    },
		afterRead (files, detail, key) {
      console.log('afterRead-files', files)
      console.log('afterRead-detail', detail)
      console.log('afterRead-key', key)
      const form = new FormData()
      form.append('file', files.file)
			Toast.loading({
				message: 'loading...',
				forbidClick: true,
				duration: 0
			})
      commonFileUploadApi(form).then(res => {
				Toast.clear()
        console.log('commonFileUploadApi', res)
        // this[key][detail.index] = {
        //   ...this[key][detail.index],
        //   ...res.data,
        //   url: res.data.domain + res.data.path,
        //   label: key === 'signInFileList' ? 0 : key === 'signOutFileList' ? 1 : key === 'materialFileList' ? 2 : key === 'sellInInventoryFileList' ? 3 : key === 'onSellInventoryFileList' ? 4 : ''
        // }
				const data = {
					key: key,
					index: detail.index,
					val: {
						...res.data,
						url: res.data.domain + res.data.path,
						label: key === 'signInFileList' ? 0 : key === 'signOutFileList' ? 1 : key === 'materialFileList' ? 2 : key === 'sellInInventoryFileList' ? 3 : key === 'onSellInventoryFileList' ? 4 : ''
					}
				}
				console.log('changeImg', data)
				this.$emit('changeImg', Object.assign({},data))
        files.status = null
      }).catch(() => {
        files.status = 'fail'
				Toast.clear()
      })
    },
		beforeRead(file) {
      console.log('beforeRead', file)
      // if (file instanceof Array && file.length) {
      //   file.forEach(item => {
      //     if (item.type !== 'image/jpeg' && item.type !== 'image/png' && item.type !== 'image/jpg') {
      //       Toast.fail('请选择正确图片格式上传')
      //       return false
      //     }
      //   })
      //   return file
      // } else {
      //   if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
      //     Toast.fail('请选择正确图片格式上传')
      //     return false
      //   }
      //   return file
      // }
      return file
    },
	}
}
