<template>
  <div class="create-onSell">
    <div v-for="(subItem, subIndex) in visitInfo.productList" :key="subItem.id">
      <div class="onSell-form">
        <img v-if="subItem.imgUrl" class="form-img" :src="subItem.imgUrl.split(',')[0]" alt="" @click.stop="imgPreview([subItem.imgUrl.split(',')[0]])">
        <img v-else class="form-img" src="@/assets/images/icon_noimg.png" alt="">
        <div class="right-item">
          <!-- <div class="product-title txt-ellipsis">{{ subItem.productName }}</div> -->
          <div class="reserve-order">
            <div class="is-order">
              <span>Pre-order or not</span>
              <van-checkbox :disabled="visitInfo.isEdit == 0" shape="square" icon-size="23" v-model="subItem.reserveOrder" />
            </div>
            <van-field
              v-if="subItem.reserveOrder"
              readonly
              clickable
              :disabled="visitInfo.isEdit == 0"
              name="datetimePicker"
              :value="subItem.orderTime"
              label=""
              placeholder="Order time" 
              @click="showTimePopup(subItem, subIndex)"
            />
          </div>
        </div>
      </div>
      <div class="onSell-title">
        <div class="product-h">Product:</div>
        <div class="name-h"></div>
        <div class="type-h">
          <van-row type="flex">
            <van-col span="24">Sell-In</van-col>
          </van-row>
        </div>
      </div>
      <div v-for="(specItem, specIndex) in subItem.specList" :key="specItem.specId"  class="spec-form">
        <div class="form-label txt-ellipsis">{{ specItem.specName }}</div>
        <div class="form-input">
          <van-row type="flex" gutter="5">
            <van-col span="24"><van-field type="digit" :disabled="visitInfo.isEdit == 0" v-model="specItem.restockCount" placeholder="" /></van-col>
          </van-row>
        </div>
      </div>
    </div>
    <div class="header-title">Product：</div>
    <div class="other-img">
      <van-uploader :disabled="visitInfo.isEdit == 0" v-model="sellInInventoryFileList2" :after-read="(files, detail) => afterRead(files, detail, 'sellInInventoryFileList')" />
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="currentDate"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import CreateMixins from './createMixins'
import { commonFileUploadApi } from '@/api/home'
import moment from 'moment'
export default {
  name: 'SellIn',
  mixins: [CreateMixins],
  props: {
    visitInfo: {
      type: Object,
      default: () => {}
    },
    sellInInventoryFileList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      value: '',
      sellInInventoryFileList2: this.sellInInventoryFileList,
      showPicker: false,
      currentDate: new Date(),
      currentIndex: ''
    }
  },
  methods: {
    showTimePopup(params, index) {
      console.log('params', params)
      this.showPicker = true
      this.currentIndex = index
    },
    onConfirm(time) {
      console.log('time', time)
      console.log(moment(time).format('YYYY-MM-DD'))
      this.$emit('changeTime', { key: 'productList', index: this.currentIndex, value: moment(time).format('YYYY-MM-DD') })
      this.showPicker = false;
    },
    // beforeRead(file) {
    //   console.log('beforeRead', file)
    //   // if (file instanceof Array && file.length) {
    //   //   file.forEach(item => {
    //   //     if (item.type !== 'image/jpeg' && item.type !== 'image/png' && item.type !== 'image/jpg') {
    //   //       Toast.fail('请选择正确图片格式上传')
    //   //       return false
    //   //     }
    //   //   })
    //   //   return file
    //   // } else {
    //   //   if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
    //   //     Toast.fail('请选择正确图片格式上传')
    //   //     return false
    //   //   }
    //   //   return file
    //   // }
    //   return file
    // },
    // afterRead (files, detail, key) {
    //   console.log('1afterRead-files', files)
    //   console.log('1afterRead-detail', detail)
    //   console.log('1afterRead-key', key)
    //   const form = new FormData()
    //   form.append('file', files.file)

    //   commonFileUploadApi(form).then(res => {
    //     console.log('commonFileUploadApi', res)
    //     this[key][detail.index] = {
    //       ...this[key][detail.index],
    //       ...res.data,
    //       url: res.data.domain + res.data.path,
    //       label: 3
    //     }
        
    //     files.status = null
    //     console.log(`this[${key}]`, key, this[key])
    //   }).catch(() => {
    //     files.status = 'fail'
    //   })
    // }
  }
}
</script>

<style lang="less" scoped>
.create-onSell {
  font-size: 12px;
  .onSell-title {
    display: flex;
    padding: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dce5f6;
    .product-h {
      font-size: 14px;
      font-weight: bold;
    }
    .product-h,
    .name-h {
      width: 75px;
    }
    .name-h {
      width: 175px;
      margin-left: 5px;
    }
    .type-h {
      flex: 1;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .onSell-form {
    padding: 10px;
    // display: flex;
    border-bottom: 1px solid #dce5f6;
    .product-title {
      width: 270px;
      margin-left: 5px;
    }
    .right-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .reserve-order {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      // padding-left: 5px;
      margin-top: 5px;
      .is-order {
        width: 150px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-weight: bold;
        font-size: 16px;
        .van-checkbox {
          margin-left: 5px;
        }
      }
      /deep/.van-cell--clickable {
        width: 200px;
        padding: 0;
        border: 1px solid #dce5f6;
      }
    }
  }
  .spec-form {
    flex: 1;
    display: flex;
    padding: 10px;
    margin-left: 5px;
    border-bottom: 1px solid #dce5f6;
    .form-label {
      width: 264px;
      margin-top: 5px;
    }
    .form-input {
      flex: 1;
    }
    /deep/.van-cell {
      padding: 0;
      border: 1px solid #dce5f6;
      input {
        padding-left: 5px;
      }
    }
  }
  /deep/.van-col {
    text-align: center;
  }
  .form-img {
    width: 100%;
    height: 100px;
    // object-fit: cover;
  }
  .header-title {
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
  }
  .other-img {
    display: flex;
    flex-wrap: wrap;
    min-height: 130px;
    padding: 10px;
    border-top: 1px solid #dce5f6;
  }
  /deep/.van-uploader {
    width: 100%;
    padding-top: 5px;
  }
  /deep/.van-uploader__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // border: 1px solid #007bff;
    border-radius: 10px;
    .van-uploader__upload {
      width: 70px;
      height: 70px;
      margin: 0;
    }
    .van-uploader__upload-icon {
      font-size: 18px;
    }
    .van-uploader__preview {
      width: 70px;
      height: 70px;
      margin: 0 5px 5px 0;
    }
    .van-uploader__file {
      width: 100%;
      height: 100%;
    }
    .van-uploader__preview-image {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
