import { render, staticRenderFns } from "./sellIn.vue?vue&type=template&id=688405a8&scoped=true"
import script from "./sellIn.vue?vue&type=script&lang=js"
export * from "./sellIn.vue?vue&type=script&lang=js"
import style0 from "./sellIn.vue?vue&type=style&index=0&id=688405a8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "688405a8",
  null
  
)

export default component.exports